/* eslint-disable react-hooks/rules-of-hooks */
import { Picture, Text } from '@components/ui'
import Data, { dataSchema } from './Modal'
import { XMarkIcon } from '@heroicons/react/24/outline'
import useProduct from '@shopify/product/use-product'
import { normalizeStorefrontProduct } from '@components/normalize'
import { cn, rmEmptyKeys } from '@components/helper'
import BannerLink from '../BannerLink'
import { useRouter } from 'next/router'
import { atobID } from '@lib/utils/tools'
import { useEffect, useState } from 'react'
import useSearch from '@shopify/product/use-search'
import { motion } from 'framer-motion'
import Icon from '@components/icons'
import { easingTransitions } from '@lib/utils/animate'
import { SearchResult } from '@commerce/types/search'
import s from './Searchbar.module.css'
import { useRelativeLink } from '@commerce/product/use-relative-link'

export const SearchBoard = ({
  data,
  onClose,
  pageType,
}: {
  data: Data
  onClose: any
  pageType?: any
}) => {
  try {
    if (!dataSchema.parse(data)) return null
  } catch (error) {
    console.error(error)
    return null
  }
  const [searchTerm, setSearchTerm] = useState('')
  const [loading, setLoading] = useState(false)
  const [stableSearchResult, setStableSearchResult] =
    useState<SearchResult | null>()

  const { locale } = useRouter()

  const { data: searchResult, isLoading: searching } = useSearch({
    searchTerm,
    locale,
  })

  useEffect(() => {
    if (searchResult) {
      setStableSearchResult(searchResult)
    }
  }, [searchResult])

  const isLoading = loading || searching

  const { data: recommendsResult } = useProduct({
    handles:
      data?.recommend.products?.reduce(
        (prev, product) => prev + ',' + product.handle,
        ''
      ) || '',
  })
  // console.log('stableSearchResult:', stableSearchResult)

  const hasResult = Boolean(searchTerm && stableSearchResult?.items.length)
  const searchedButNoResult = Boolean(searchTerm && !isLoading)

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: {
          ease: easingTransitions?.easeInQuart,
          duration: 0.3,
          delay: 0.15,
        },
      }}
      exit={{
        opacity: 0,
        transition: {
          ease: easingTransitions?.easeInQuart,
          duration: 0.3,
          delay: 0.15,
        },
      }}
      className="content flex w-full justify-center overflow-hidden leading-[1.2] l:fixed l:left-0 l:top-0 l:z-[999] l:h-full l:bg-[#F7F8FA] l:px-[30px]"
    >
      <div className="mx-auto w-[1064px] pb-[40px] pt-[20px]">
        <div
          className={cn(
            'mb-[24px] flex h-[56px] w-full items-center justify-center overflow-hidden bg-[#F7F8FA]'
          )}
        >
          <div className="mx-auto flex w-[1064px] justify-end overflow-hidden">
            <form
              onSubmit={(e) => {
                e.preventDefault()
                window.location.href = `${
                  locale === 'us' ? '' : '/' + locale
                }/search?q=${searchTerm.toLowerCase()}`
              }}
              className="relative flex h-[40px] w-full items-center rounded-[20px] bg-white l:rounded-none l:border-b l:border-b-black l:bg-[#F7F8FA]"
            >
              {searchTerm && isLoading ? (
                <Loading />
              ) : (
                <motion.button
                  type="submit"
                  className="absolute left-[18px] l:left-0"
                >
                  <Icon
                    iconKey="search"
                    className="!h-[24px] !w-[24px] hover:fill-anker-color"
                  ></Icon>
                </motion.button>
              )}

              <input
                autoFocus
                placeholder={data?.placeholder || 'Find what you seek'}
                className="h-full flex-1 rounded-[20px] bg-white pl-[46px] text-[18px] caret-anker-color placeholder:text-[18px] placeholder:text-[#AFAFAF] focus:outline-none l:bg-[#F7F8FA] l:pl-[26px] l:focus:outline-none"
                onChange={(e) => {
                  let timer
                  clearTimeout(timer)
                  setLoading(true)
                  timer = setTimeout(() => {
                    setSearchTerm(e.target.value)
                    setLoading(false)
                  }, 500)
                }}
              />

              <button
                className="absolute right-[10px] l:right-0"
                aria-label="close search"
                onClick={onClose}
                type="button"
              >
                <XMarkIcon className="h-[24px] w-[24px] stroke-black stroke-[1.5]" />
              </button>
            </form>
          </div>
        </div>
        <div className="px-[20px]">
          {hasResult ? (
            <Result
              data={data}
              searchResult={stableSearchResult}
              searchTerm={searchTerm}
            />
          ) : (
            <>
              {searchedButNoResult && (
                <Text
                  html={data?.noResult}
                  className="w-full text-center text-[16px] font-semibold text-[#A7A7A7]"
                />
              )}
              <Recommends
                pageType={pageType}
                data={data}
                recommendsResult={recommendsResult}
                searchedButNoResult={searchedButNoResult}
              />
            </>
          )}
        </div>
      </div>
    </motion.div>
  )
}

const MobileSearchBoard = ({
  data,
  onClose,
  pageType,
}: {
  data: Data
  onClose: any
  pageType?: any
}) => {
  const [stableSearchResult, setStableSearchResult] =
    useState<SearchResult | null>()

  const { locale } = useRouter()
  const [searchTerm, setSearchTerm] = useState('')
  const [loading, setLoading] = useState(false)

  const { data: searchResult, isLoading: searching } = useSearch({
    searchTerm,
    locale,
  })

  useEffect(() => {
    if (searchResult) {
      setStableSearchResult(searchResult)
    }
  }, [searchResult])

  const isLoading = loading || searching

  const { data: recommendsResult } = useProduct({
    handles:
      data?.recommend.products?.reduce(
        (prev, product) => prev + ',' + product.handle,
        ''
      ) || '',
  })
  // console.log('stableSearchResult:', stableSearchResult)

  const hasResult = Boolean(searchTerm && stableSearchResult?.items.length)
  const searchedButNoResult = Boolean(searchTerm && !isLoading)

  return (
    <motion.div
      initial={{
        x: '100%',
      }}
      animate={{
        x: 0,
        transition: {
          ease: easingTransitions?.easeOutQuart,
          duration: 0.3,
        },
      }}
      exit={{
        x: '100%',
        transition: {
          ease: easingTransitions?.easeInQuart,
          duration: 0.3,
        },
      }}
      className={cn(s.MobileSearchBoard)}
    >
      <div className="mx-auto flex w-[1064px] flex-col overflow-y-auto pb-[40px] pt-[20px] l:px-[24px]">
        <form
          onSubmit={(e) => {
            e.preventDefault()
            window.location.href = `${
              locale === 'us' ? '' : '/' + locale
            }/search?q=${searchTerm.toLocaleLowerCase()}`
          }}
          className="relative mb-[20px] flex h-[40px] w-full items-center rounded-[20px] bg-white"
        >
          {searchTerm && isLoading ? (
            <Loading />
          ) : (
            <button type="submit" className="absolute left-[18px]">
              <Icon
                iconKey="search"
                className="!h-[24px] !w-[24px] hover:fill-anker-color"
              ></Icon>
            </button>
          )}

          <input
            autoFocus
            placeholder={data?.placeholder || 'Find what you seek'}
            className="h-full flex-1 rounded-[20px] pl-[46px] text-[18px] caret-anker-color placeholder:text-[18px] placeholder:font-semibold placeholder:text-[#AFAFAF] focus:outline-none md:h-[40px] l:placeholder:text-[14px] l:focus:outline-none"
            onChange={(e) => {
              let timer
              clearTimeout(timer)
              setLoading(true)
              timer = setTimeout(() => {
                setSearchTerm(e.target.value)
                setLoading(false)
              }, 500)
            }}
          />

          <button
            className="absolute right-[10px]"
            aria-label="close search"
            onClick={onClose}
            type="button"
          >
            <XMarkIcon className="h-[24px] w-[24px] stroke-black stroke-[1.5]" />
          </button>
        </form>
        <div className="px-[20px]">
          {hasResult ? (
            <Result
              data={data}
              searchResult={stableSearchResult}
              searchTerm={searchTerm}
            />
          ) : (
            <>
              {searchedButNoResult && (
                <Text
                  html={data?.noResult}
                  variant="paragraph"
                  className="mb-[16px] w-full text-center text-[16px] font-medium text-[#A7A7A7] "
                />
              )}
              <Recommends
                data={data}
                recommendsResult={recommendsResult}
                searchedButNoResult={searchedButNoResult}
                pageType={pageType}
              />
            </>
          )}
        </div>
      </div>
    </motion.div>
  )
}

export default MobileSearchBoard

const Result = ({
  data,
  searchResult,
  searchTerm,
}: {
  data: Data
  searchResult: any
  searchTerm: string
}) => {
  const { locale } = useRouter()
  const boldSearchWord = (text: string, searchText: string) => {
    try {
      const wordIndex = text?.search(new RegExp(searchText, 'i'))
      if (searchText && wordIndex !== -1) {
        const word = text.slice(wordIndex, wordIndex + searchText.length)
        return text.replace(word, `<span class='text-black'>${word}</span>`)
      } else {
        return text
      }
      // start_ai_generated
    } catch (e) {
      console.log('e', e)
      return text
    }
    // end_ai_generated
  }
  const getLink = (item: any) => {
    switch (item.type) {
      case 'product':
        return `${locale === 'us' ? '' : '/' + locale}/products${
          item.path
        }?search_category=autofill&q=${searchTerm.toLowerCase()}`
      case 'page':
        return `/${locale}${
          item.path
        }?search_category=autofill&q=${searchTerm.toLowerCase()}`
      case 'article':
        return `${locale === 'us' ? '' : '/' + locale}/blogs/${
          item.blog.handle
        }/${item.handle}?search_category=autofill&q=${searchTerm.toLowerCase()}`
      default:
        break
    }
  }
  if (!searchTerm || !searchResult?.items.length) {
    return null
  }

  return (
    <div className="l:mt-[10px]">
      <Text
        html={data?.result}
        className="text-[20px] font-semibold l:text-[18px] "
      />
      <div className="mt-[10px] grid">
        {searchResult.items.map((item: any) => {
          if (item?.metafields?.seoSetting?.noindex) return ''
          return (
            <motion.a
              key={item.id}
              dangerouslySetInnerHTML={{
                __html: boldSearchWord(item.title, searchTerm),
              }}
              href={getLink(item)}
              className="w-full rounded-[8px] py-[6px] pl-[8px] text-[16px] font-medium text-[#A7A7A7] hover:bg-white hover:text-black l:pl-[10px]"
            />
          )
        })}
      </div>
      {searchResult?.totalCount > 8 && (
        <a
          className="mx-auto mt-[20px] block w-fit text-[16px] font-semibold text-anker-color"
          href={`${
            locale === 'us' ? '' : '/' + locale
          }/search?search_category=all&q=${searchTerm.toLowerCase()}`}
        >
          {data?.viewMore}
        </a>
      )}
    </div>
  )
}

const Recommends = ({
  data,
  recommendsResult,
  searchedButNoResult,
  pageType,
}: {
  data: Data
  recommendsResult: any
  searchedButNoResult: boolean
  pageType?: any
}) => {
  const { locale } = useRouter()
  const { setRelativeLink } = useRelativeLink()
  if (!data?.recommend.products?.length) {
    return null
  }

  return (
    <div className="grid gap-[22px] l:gap-[20px]">
      <Text
        html={searchedButNoResult ? data?.guessSearch : data?.recommend.label}
        className="text-[20px] font-semibold l:text-[18px]"
      />

      <div className="grid grid-cols-3 gap-[28px] l:grid-cols-1 l:gap-[20px]">
        {data?.recommend.products?.map((p, i) => {
          const product = normalizeStorefrontProduct(p, recommendsResult)
          if (!product) return null
          const [initial, animate] = [
            {
              opacity: 0,
              x: -30,
            },
            {
              opacity: 1,
              x: 0,
              transition: {
                ease: easingTransitions?.easeInOutQuart,
                duration: 0.3,
                delay: 0.2 + 0.05 * i,
              },
            },
          ]
          return (
            <motion.div
              key={product.sku}
              className="group relative flex l:hover:rounded-[10px] l:hover:bg-white"
              initial={initial}
              animate={animate}
            >
              <BannerLink
                to={
                  product?.custom_link
                    ? setRelativeLink({ link: product?.custom_link })
                    : `${locale === 'us' ? '' : '/' + locale}/products/${
                        product.handle
                      }?variant=${atobID(
                        product.variantId
                      )}&ref=search_recommend`
                }
              />
              <Picture
                className="flex h-[68px] w-[68px] shrink-0 items-center justify-center rounded-[8px] bg-white l:h-[74px] l:w-[74px] [&_img]:!h-full [&_img]:object-contain"
                source={product.image}
              />
              <div className="ml-[8px] pt-[8px]">
                <div className="flex h-[14px] items-end">
                  {product.tag && (
                    <Text
                      html={product.tag}
                      className={cn(
                        'text-[14px] font-bold leading-3 md:text-[12px]'
                      )}
                      style={rmEmptyKeys({ color: product.tagColor })}
                    ></Text>
                  )}
                  {product.tagImg && (
                    <Picture
                      className="h-full"
                      imgClassName="w-auto !h-full object-contain"
                      source={product.tagImg}
                    />
                  )}
                </div>
                <Text
                  className="mt-[4px] line-clamp-2 text-[16px] font-medium text-black group-hover:text-anker-color l:text-[16px] l:group-hover:text-black"
                  html={
                    pageType === 'powerStation' || pageType === 'hes'
                      ? product.custom_title ||
                        product?.variantMetafields?.infos?.name ||
                        product.title
                      : product.title
                  }
                ></Text>
              </div>
            </motion.div>
          )
        })}
      </div>
    </div>
  )
}

const Loading = () => (
  <svg
    className="absolute left-[10px] h-[26px] w-[26px] animate-spin text-black duration-500 md:left-[18px]"
    xmlns="http://www.w3.org/2000/svg"
    width="200px"
    height="200px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke="#000000"
      strokeWidth="10"
      r="35"
      strokeDasharray="164.93361431346415 56.97787143782138"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      ></animateTransform>
    </circle>
  </svg>
)

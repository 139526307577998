import cn from 'classnames'
import s from './index.module.css'
import { Text, Picture } from '@components/ui'
import { easingTransitions } from '@lib/utils/animate'
import { motion, AnimatePresence } from 'framer-motion'
import { useRelativeLink } from '@commerce/product/use-relative-link'

const Index = ({ notificationPopLeft, data, setNotificationPopLeft }) => {
  const { setRelativeLink } = useRelativeLink()
  if (!data) return null
  return (
    <AnimatePresence>
      {notificationPopLeft && (
        <>
          <motion.div
            style={{ left: notificationPopLeft }}
            className={cn(
              'absolute z-[11] flex w-[390px] flex-col items-center rounded-b-lg border border-t-0 border-[#E2E2E2] bg-[#F7F8FA] md:top-[51px] md:right-[24px] md:z-[99]',
              { ['overflow-y-scroll']: data?.list?.length > 4 }
            )}
            initial={{ height: 0 }}
            exit={{ height: 0 }}
            animate={{ height: data?.list?.length > 4 ? 460 : 'auto' }}
            transition={{
              duration: 0.3,
              delay: 0.1,
              ease: easingTransitions?.easeInOutQuart,
            }}
          >
            <motion.div
              className="flex w-full flex-col py-[24px] px-[24px]"
              initial={{
                opacity: 0,
                x: -30,
              }}
              animate={{
                opacity: 1,
                x: 0,
                transition: {
                  ease: easingTransitions?.easeInQuart,
                  duration: 0.3,
                  delay: 0.15,
                },
              }}
              exit={{
                opacity: 0,
                x: -30,
                transition: {
                  ease: easingTransitions?.easeInQuart,
                  duration: 0.3,
                },
              }}
            >
              <Text
                html={data.title}
                className={s.notificationTitle}
                variant="paragraph"
              />
              <ul className={s.notificationCol}>
                {data?.list?.map((item, index) => (
                  <li key={index}>
                    <a
                      className={s.notificationBox}
                      href={`${setRelativeLink({
                        link: item?.href,
                      })}?ref=naviNotification`}
                    >
                      <Picture
                        className={cn('h-[80px] w-[80px] flex-none')}
                        source={`${item?.mobImg} 768`}
                      />
                      <div className="flex flex-col gap-[6px]">
                        {item.title && (
                          <p
                            className={s.notificationBoxTitle}
                            dangerouslySetInnerHTML={{
                              __html: item.title,
                            }}
                          />
                        )}

                        {item.desc && (
                          <p
                            className={s.notificationBoxDesc}
                            dangerouslySetInnerHTML={{
                              __html: item.desc,
                            }}
                          />
                        )}
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </motion.div>
          </motion.div>

          <motion.div
            key="dropDownBox-notification-mask"
            className={s.mask}
            transition={{
              duration: 0.3,
              ease: easingTransitions?.easeOutQuart,
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onMouseEnter={() => setNotificationPopLeft(false)}
            onClick={() => setNotificationPopLeft(false)}
          />
        </>
      )}
    </AnimatePresence>
  )
}

export default Index

export const easingFunctions = {
  // reference:"https://www.xuanfengge.com/easeing/easeing/",
  // 参数 t 为小于 1 的小数
  linear: function (t) {
    return t
  },
  easeInQuad: function (t) {
    return t * t
  },
  easeOutQuad: function (t) {
    return t * (2 - t)
  },
  easeInOutQuad: function (t) {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t
  },
  easeInCubic: function (t) {
    return t * t * t
  },
  easeOutCubic: function (t) {
    return --t * t * t + 1
  },
  easeInOutCubic: function (t) {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
  },
  easeInQuart: function (t) {
    return t * t * t * t
  },
  easeOutQuart: function (t) {
    return 1 - --t * t * t * t
  },
  easeInOutQuart: function (t) {
    return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t
  },
  easeInQuint: function (t) {
    return t * t * t * t * t
  },
  easeOutQuint: function (t) {
    return 1 + --t * t * t * t * t
  },
  easeInOutQuint: function (t) {
    return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t
  },
}

export const easingTransitions = {
  // https://easings.net/#
  easeInQuart: [0.5, 0, 0.75, 0], // 常用于 淡出 过渡（ 1->0 ）
  easeOutQuart: [0.25, 1, 0.5, 1], // 常用于 淡入 过渡（ 0->1 ）
  easeInOutQuart: [0.76, 0, 0.24, 1], // 常用于 变化过渡 过渡（ 1->2 ）
}

import { z } from 'zod'

export const dataSchema = z.object({
  placeholder: z.string().optional(),
  guessSearch: z.string().optional(),
  result: z.string().optional(),
  viewMore: z.string().optional(),
  noResult: z.string().optional(),
  recommend: z.object({
    label: z.string().optional(),
    products: z
      .object({
        handle: z.string().nonempty(),
        sku: z.string().nonempty(),
        tag: z.string().optional(),
        tagColor: z.string().optional(),
        tagImg: z.string().optional(),
      })
      .array()
      .optional(),
  }),
})

type Data = z.infer<typeof dataSchema>
export default Data
